import React, {Component} from 'react';
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import BallonIcon from '../../images/ballon.gif';
import SettingIcon from '../../images/settings-icon.svg';
import UserIcon from '../../images/user-icon.png';
import FormData from 'form-data';
import axios from "axios";
import { setAppVars } from "../../actions/AppVars.action";
import { setCurrentRoom } from "../../actions/Room.action";
import imageEXIF from "../others/imageEXIF.component";
import ImageCutter from "../others/ImageCutter.component";

const isEmpty = require('is-empty');
// Merge the object into the extended object
      // for replace Object.assign that is not suport on old safari versions
      const merge = function (obj, obj2 = {}) {
        for (var prop in obj2) {
          if (obj2.hasOwnProperty(prop)) {
            // Push each value from `obj` into `extended`
            obj[prop] = obj2[prop];
          }
        }
        return obj;
      };
class RoomHeader extends Component {
	constructor() {
	    super();
		this.handleChange = this.handleChange.bind(this);
		this.handleChangeFile = this.handleChangeFile.bind(this);
		this.image = this.image.bind(this);
		this.inputEditing = this.inputEditing.bind(this);
		this.inputStyle = this.inputStyle.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	    this.state = {
	      	id:'',
	      	name:'',
	      	birth_year:'',
	      	decease_year:'',
	      	photo_id:'',
	      	image:'',
	      	image_error:false,
	      	image_preview:'',
	      	editing_name:false,
	      	editing_year:false,
	      	editing_image:false,
	      	mass_details:'',
      		editing_mass_details:false,
	      	im_admin:false,
	      	edit:false
	    }
	}
	handleChange = event => {
		if (!this.state.im_admin)
			return 0;
		const {name, value} = event.target;
		this.setState({
			[name] : value
		});
	}
	handleChangeFile = async event => {
		if (!this.state.im_admin)
			return 0;
		const file = event.target.files[0];
		const result = await imageEXIF.getImageUrl(file);
		new ImageCutter(result, 600,(rslt)=>{
		   this.setState({image_preview:rslt.base64,image:rslt.blob});
		});
	}
	image(){
			let image = null;
			if (this.state.image) {
					image = this.state.image_preview;
			}else{
				if (this.state.photo_id && !this.state.image_error) {
					image = process.env.REACT_APP_API_URL+'/api/parlor-admin/funerals/'+this.state.id+'/photo';
				}else{
					image = UserIcon;
				}
			}
			return image;
	}
	inputEditing(prop){
		console.log('fired');
		if (!this.state.im_admin)
			return 0;
		let state = JSON.parse('{"editing_name":false,"editing_year":false, "editing_image":false,"editing_mass_details":false}');
		state[prop] = true;
		let focus = ()=>{
			let target = '';
			if(this.state.editing_name)
				target = 'name';
			if(this.state.editing_mass_details)
				target = 'mass_details';
			if(target)
				document.querySelector('textarea[name="'+target+'"]').focus();
		};
		this.setState(state,focus);
	}
	inputStyle(prop, element){
		// if (!this.state.im_admin)
		// 	return 0;
		if (element === 'text') {
			if (this.state[prop]) {
				return JSON.parse('{"display":"none"}');
			}else{
				return JSON.parse('{}');
			}
		}else{
			if (!this.state[prop]) {
				return JSON.parse('{"display":"none"}');
			}else{
				return JSON.parse('{}');
			}
		}
	}
	onSubmit(prop) {
		if (!this.state.im_admin || (typeof this.props.app_vars !== 'undefined' && this.props.app_vars.edit_load_percentage > 0))
			return 0;
		console.log('pass')
		let state = JSON.parse('{"editing_name":false,"editing_year":false, "editing_image":false,"editing_mass_details":false}');
		console.log();
		this.setState(state);
		var formData = new FormData();

			if (this.state.image) {
				formData.append("image", this.state.image);
			}
			formData.append("name", this.state.name);
			formData.append("funeral_id", this.props.room.id);
			formData.append("web_user_id", this.props.user.web_user.id);
			// formData.append("room", this.state.room);
			if (!isEmpty(this.state.birth_year))
				formData.append("birth_year", this.state.birth_year);
			if (!isEmpty(this.state.decease_year))
				formData.append("decease_year", this.state.decease_year);
			// if (!isEmpty(this.state.mass_details))
			formData.append("mass_datails", this.state.mass_details);
			// formData.append("funeral_start_date", this.state.funeral_start_date);
			// formData.append("funeral_end_date", this.state.funeral_end_date);
			// formData.append("mass_date", this.state.mass_date);
			// formData.append("mass_datails", this.state.mass_datails);
			// formData.append("burial_date", this.state.burial_date);
			// formData.append("burial_datails", this.state.burial_datails);
			// formData.append("is_open", this.state.is_open?1:0);
			// formData.append("funeral_parlor_id", this.props.user.funeral_parlor_admin.funeral_parlor.id);
			// formData.append("contact_email", this.state.contact_email );
			// formData.append("download_link", this.state.download_link );
			const this_ = this;
			axios.post(process.env.REACT_APP_API_URL+'/api/web-user/room/update', formData, {
						"headers": {
							"Content-Type": "application/x-www-form-urlencoded",
							'content-type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW'
						},
						onUploadProgress: (event)=>{
			              const percentage = Math.round( (event.loaded * 100) / event.total );
			            	// this.setState({load_percentage:percentage});
			            	// console.log('load:',percentage);
			            	let vars = {app_vars:{}};
			            	vars.app_vars.edit_load_percentage = percentage;
			            	this.props.setAppVars(vars);
			            }
			}).then(function(res){
					console.log('SUCCESS!!',res);
					let vars = {app_vars:{}};
			        vars.app_vars.edit_load_percentage = 0;
			        this_.props.setAppVars(vars);
			        this_.state.image = null;

			        let room = this_.props.room;
			        room.name = res.data.room.name;
			        room.mass_datails = res.data.room.mass_datails;
					room.birth_year = res.data.room.birth_year;
					room.decease_year = res.data.room.decease_year;
					room.updated_at = res.data.room.updated_at;
					room.messages = res.data.room.messages;
					room.mass_date_formated = res.data.room.mass_date_formated;
					room.mass_date_formated_info = res.data.room.mass_date_formated_info;
					room.burial_date_formated = res.data.room.burial_date_formated;
					room.burial_date_formated_info = res.data.room.burial_date_formated_info;
			        this_.props.setCurrentRoom({funeral:room});

			        // document.querySelector('.room-img').src = document.querySelector('.room-img').src+"?r="+new Date().getTime();
					// alert('Actualizado');
					// window.location.pathname = "/dashboard";
					// this.props.history.push = "/dashboard"; 
			})
			// .catch(function(err){
			// 		console.log('FAILURE!!', err.response);
			// });

	}
	yearsSelectOptios(minYear) {
			let currYear = (new Date()).getFullYear();
			if (isEmpty(minYear))
				minYear = (new Date()).getFullYear();
			let options = [];
			options.push(<option key="-1" value="">Año</option>)
			while(currYear >= minYear){
				options.push(<option key={currYear} value={currYear}>{currYear}</option>);
				currYear--;
			}
			return options;
	}
	imageAdmin(){
		if (this.state.im_admin && this.state.edit) {
			return <div>
				<label htmlFor="files" className="pin pin-edit" onClick={(e)=>{this.inputEditing('editing_image')}} style={merge({top:'4%',left:'68%'},this.inputStyle('editing_image', 'text'))}></label>
				<label  className="pin pin-check" onClick={()=>this.onSubmit('editing_image')} style={merge({top:'4%',left:'68%'},this.inputStyle('editing_image', 'input'))}></label>
				<input type="file" className="form-control-file d-none" id="files" name="files" accept="image/*"
						onChange={this.handleChangeFile}
				/>
			</div>;
		}
	}
	nameAdmin(){
		if (this.state.im_admin && this.state.edit) {
			return <div>
				<label className="pin pin-edit" onClick={()=>this.inputEditing('editing_name')} style={merge({top:'-5%',left:'80%'},this.inputStyle('editing_name', 'text'))}></label>
				<label  className="pin pin-check" onClick={()=>this.onSubmit('editing_name')} style={merge({top:'-5%',left:'80%'},this.inputStyle('editing_name', 'input'))}></label>
				<h2 
					className="m-0 p-0 room-title" 
					style={Object.assign(
						{lineHeight:'1'},
						this.inputStyle('editing_name', 'input'))
					}>
					<textarea
						name="name"
						className="w-100 text-center text-dark border-0 input-app"
						style={{fontWeight:'inherit'}}
						value={this.state.name}
						onChange={this.handleChange}
						required
						onBlur={()=>this.onSubmit('editing_name')}
					/>
				</h2>
			</div>;
		}
	}
	birthYearAdmin(){
		if (this.state.im_admin) {
			return <div className="d-inline-block">
				<label className="pin pin-edit" onClick={()=>this.inputEditing('editing_year')} style={merge({top:'-53%',left:'75%'},this.inputStyle('editing_year', 'text'))}></label>
				<label  className="pin pin-check" onClick={()=>this.onSubmit('editing_year')} style={merge({top:'-53%',left:'75%'},this.inputStyle('editing_year', 'input'))}></label>
				<select
					name="birth_year"
					id="birth_year"
					className="select-app"
					style={merge({fontWeight:'inherit'},this.inputStyle('editing_year', 'input'))}
					value={isEmpty(this.state.birth_year)?'':this.state.birth_year}
					onChange={this.handleChange}
					required
				>
					{this.yearsSelectOptios(1900)}
				</select>
			</div>;
		}
	}
	deceaseYearAdmin(){
		if (this.state.im_admin) {
			return <div className="d-inline-block">
				<select
					name="decease_year"
					className="select-app"
					style={merge({fontWeight:'inherit'},this.inputStyle('editing_year', 'input'))}
					value={isEmpty(this.state.decease_year)?'':this.state.decease_year}
					onChange={this.handleChange}
					required
				>
					{this.yearsSelectOptios(this.state.birth_year)}
				</select>
			</div>;
		}
	}
	massDetailsAdmin(){
	    if (this.state.im_admin && this.state.edit) {
	      return <div className="">
	          <label className="pin pin-edit" onClick={()=>this.inputEditing('editing_mass_details')} style={Object.assign({top:'-30%',left:'80%'},this.inputStyle('editing_mass_details', 'text'))}></label>
	          <label  className="pin pin-check" onClick={()=>this.onSubmit('editing_mass_details')} style={Object.assign({top:'-30%',left:'80%'},this.inputStyle('editing_mass_details', 'input'))}></label>
	          <p style={this.inputStyle('editing_mass_details', 'input')}>
	            <textarea
	                name="mass_details"
	                className="w-100 text-secondary border-0 input-app text-center"
	                style={{fontWeight:'inherit', minHeight:'5rem'}}
	                value={isEmpty(this.state.mass_details)?'':this.state.mass_details}
	                onChange={this.handleChange}
	                required
	                maxLength="125"
	                onBlur={()=>this.onSubmit('editing_mass_details')}
	                placeholder="Escribe la escuela y el aula aquí"
	            />
	          </p>
	      </div>;
	    }
  	}
	componentDidMount(){
		this.setState({
			id:this.props.room.id,
			name:this.props.room.name,
			photo_id:this.props.room.photo_id,
			birth_year:this.props.room.birth_year,
			decease_year:this.props.room.decease_year,
			mass_details:this.props.room.mass_datails,
			im_admin:(!isEmpty(this.props.user))?(this.props.user.web_user.funerals_to_manage.indexOf(this.props.room.id)>=0):false
		});
	}
	render() {
				return (
						<div className="row pt-5">
							<div className="col-12 d-flex justify-content-between align-items-center">
								<div className="ml-2" onClick={()=>{
									this.setState({edit:!this.state.edit});
								}}>
									<img src={SettingIcon} alt="" style={{width:'1.5rem'}}/>
								</div>
								<div className="block-candle d-flex justify-content-center">
									<figure className="text-center my-2">
							      		<Link to="/room/candle" 
							      			onClick={(e)=>{
													if (window.location.pathname === "/room/candle")
														e.preventDefault();
											}}
							      		>
							      			<img src={BallonIcon} style={{width:'4rem'}} className="img-fluid img-circle" alt="" />
							      		</Link>
							      		<figcaption className="font-weight-bolder">
											<Link 
												to="/room/candle" 
												onClick={(e)=>{
													if (window.location.pathname === "/room/candle")
														e.preventDefault();
												}}
											>
												Infla un Globo
											</Link>
										</figcaption>
									</figure>
								</div>
							</div>
							<div className={"col-12 d-flex text-center " + (!this.props.room.photo_id?'pt-room-header':'')}  style={{marginTop:'-3rem'}}>
								<img src={this.image()} className="img-fluid img-circle room-img gradient" alt="" onError={()=>{this.setState({image_error:true})}} 
									onClick={()=>{
										if (!this.state.im_admin || window.location.pathname !== "/room/info")
											return 0;
										this.inputEditing('editing_image');
										document.querySelector('label[for="files"]').click();
									}}
									onLoad={(event)=>{
										event.target.classList.remove('gradient');
									}}
								/>
								{this.imageAdmin()}
							</div>
							<div className="col-12 text-center px-5">
								{this.nameAdmin()}
								<h2 
									className="text-dark m-0 mt-2 p-0 room-title break-word" 
									style={Object.assign(
										{lineHeight:'1'},
										this.inputStyle('editing_name', 'text')
									)}
									onClick={()=>{
										if (!this.state.edit)
											return;
										this.inputEditing('editing_name')
									}}
								>
									{this.state.name}
								</h2>
								{(()=>{
									if (!isEmpty(this.props.room.mass_date)) {
										return[
											<hr key="hr-geader" className="mx-5 my-2"/>,
											<span key="span-header">{(new Date(this.props.room.mass_date.replace(/-/g, "/"))).toLocaleDateString('es-ES', { year: 'numeric', month: 'long', day: 'numeric' })}</span>
										];
									}
								})()}
								
								{/*<h3 className="text-secondary room-year">
									{this.birthYearAdmin()}
									<span style={this.inputStyle('editing_year', 'text')} onClick={()=>this.inputEditing('editing_year')}>{this.state.birth_year}</span>

									<span className={isEmpty(this.state.birth_year) || isEmpty(this.state.decease_year)?'d-none':''}>-</span>
									{this.deceaseYearAdmin()}
									<span style={this.inputStyle('editing_year', 'text')} onClick={()=>this.inputEditing('editing_year')}>{this.state.decease_year}</span>
								</h3>*/}
							</div>
							{(()=>{
								let text = this.state.mass_details;
								if (isEmpty(this.state.mass_details)&&this.state.im_admin) {
									text = 'Escribe la escuela y el aula aquí';
								}else if(isEmpty(this.state.mass_details)&&!this.state.im_admin){
									text="";
								}
								return (<div className="col-12 text-center px-4 mt-3 text-secondary">
									{this.massDetailsAdmin()}
				                    <p 
				                      onClick={()=>{
				                      	if (!this.state.edit)
											return;
				                      	this.inputEditing('editing_mass_details');
				                      }}
				                      style={merge({wordBreak:'break-all'},this.inputStyle('editing_mass_details', 'text'))}
				                    >{text}</p>
								</div>)
							})()}
						</div>
				);
	}
}
RoomHeader.propTypes = {
	setAppVars: PropTypes.func.isRequired,
	setCurrentRoom: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
	room: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired,
	app_vars: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  user: state.user,
  room: state.room,
  errors: state.errors,
  app_vars: state.app_vars
});
export default connect(
  mapStateToProps,
  { setAppVars,setCurrentRoom  }
)(RoomHeader);
