import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Sidebar from "../layout/Sidebar.component";
import Navbar from "../layout/Navbar.component";
import NavbarFooter from "../layout/NavbarFooter.component";
import LoginModal from "../auth/LoginModal.component";
import RoomHeader from "../layout/RoomHeader.component";
import RedBalloonIcon from '../../images/red_balloon.gif';
import YellowBalloonIcon from '../../images/yellow_balloon.gif';
import VioletBalloonIcon from '../../images/violet_balloon.gif';
import GreenBalloonIcon from '../../images/green_balloon.gif';
import BlueBalloonIcon from '../../images/ballon.gif';
import { updateRoom } from "../../actions/Room.action";
import axios from "axios";
import $ from "jquery";

const isEmpty = require('is-empty');

class CandleRoom extends Component {
  constructor() {
    super();
    this.candles = this.candles.bind(this);
    this.addCandleButtom = this.addCandleButtom.bind(this);
    this.updateRoom = this.updateRoom.bind(this);
    this.setLoginModal = this.setLoginModal.bind(this);
    this.setBeforeAction = this.setBeforeAction.bind(this);
    this.state = {
      my_candle:false,
      login_modal:"hidden",
      before_action:null,
      sidenav:false
    };
  }
  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };
  onSubmit = e => {
    e.preventDefault();
    if (isEmpty(this.props.user)) {
      // window.location.pathname = "/register";
      // this.props.history.push("/register");
      this.setState({
        before_action:'candle'
      })
      $("#login-modal").modal("show");
      this.setLoginModal("show");
      return 0;
    }
    var form = document.getElementsByClassName('needs-validation')[1];
    if (form.checkValidity()) {
      const obj = {
        web_user_id:this.props.user.web_user.id,
        funeral_id:this.props.room.id
      }
      axios.post(process.env.REACT_APP_API_URL+"/api/candle/on", obj)
      .then(res => {
        if (res.data.success) {
          console.log("submit",res);

          const obj = {
            code:this.props.room.id,
            user_id:!isEmpty(this.props.user)?this.props.user.id:null
          }; 
          this.props.updateRoom(obj, this.props.history);
          this.setState(()=>{
            return {my_candle:true};
          });
        }else{
          console.log("submit",res);

        }
      });
    }
  }
  candles(){
    let candles = "";
    const balloons = [RedBalloonIcon, YellowBalloonIcon, VioletBalloonIcon, GreenBalloonIcon,BlueBalloonIcon];
    if (isEmpty(this.props.room.web_user_candles)) {
      candles = <div className="row px-4 pt-0">
                    <div className="col-sm-12 col-md-6">
                      <p className="text-light">Aún no hay Brindis, se el primero en encender una.</p>
                    </div>
                </div>
    }else{
      candles = this.props.room.web_user_candles.map((candle, index)=>{
        let balloon = balloons[Math.floor(Math.random() * balloons.length)];
        const sufix = (!isEmpty(this.props.user) && this.state.my_candle && candle.user.id === this.props.user.id)?" (Tu)":"";
        let name = this.props.room.web_user_candles[index].user.name;
        if(this.props.room.web_user_candles[index].user.name.split(' ').length>1){
            name = <small>{candle.user.name+sufix}</small>
        }
        return(
          <div key={index} className="col-4 col-md-3 pb-80">
            <figure className="text-center fig-candle">
              <img src={balloon} className="img-fluid img-circle" alt="" />
              <figcaption>
                {name}
              </figcaption>
            </figure>
          </div>
        );
      });
    }
    return candles;
  }
  addCandleButtom(){
    if (!this.state.my_candle) {
      return(
        <form noValidate className="needs-validation" onSubmit={this.onSubmit}>
          <input type="hidden" name="web_user_id" value={!isEmpty(this.props.user)?this.props.user.web_user.id:""}/>
          <input type="hidden" name="funeral_id" value={this.props.room.id}/>
          <div className="row">
            <div className="col-12 text-center">
                <button id="candle" type="submit" className="btn bg-third-color text-white btn-et min-h-3"
                  onClick={()=>{
                    // window.gtag('event', 'click', {
                    //   'event_category': 'app',
                    //   'event_label': 'Botón Encender Vela',
                    //   // 'value': <value>
                    // });
                  }}
                >
                    Infla un Globo
                </button>
              </div> 
          </div>
          <div className="row">
            <div className="col-12">
              <div className="block-message mt-3 bg-second-color text-white">
                <p className="m-0">Globos para {this.props.room.name} como muestra de buenos deseos.</p>
              </div> 
            </div>
          </div>
                  
        </form>
      );
    }else{
      return(
        <div className="row">
          <div className="col-12 text-center text-dark">
              <p>Muchas Gracias</p>
          </div>
          <div className="col-12">
            <div className="block-message bg-second-color text-white">
              <p>Globos para {this.props.room.name} como muestra de buenos deseos.</p>
            </div> 
          </div> 
        </div> 
      );
    }
  }
  updateRoom(){
    const obj = {
      code:this.props.room.id,
      user_id:!isEmpty(this.props.user)?this.props.user.id:null
    };
    this.props.updateRoom(obj, this.props.history);
    window.location.reload();
    // Router.dispatch(window.location.pathname, null);
     // this.props.history.pushState(null, '/');
     // this.props.history.pushState(null, window.location.pathname);
  }
  componentDidMount(){
    if (isEmpty(this.props.room)) {
      this.props.history.push('/code');
    }
    // if (isEmpty(this.props.user)) {
    //   this.props.history.push('/login');
    // }
    this.props.room.web_user_candles.map((candle, index)=>{
        if(!isEmpty(this.props.user) && !this.state.my_candle && candle.user.id === this.props.user.id){
          this.setState(()=>{
            return {my_candle:true};
          });
        }
        return 0;
    });

    if (!isEmpty(localStorage.before_action) && localStorage.before_action === "candle" && !isEmpty($("#"+localStorage.before_action))) {
      $("#"+localStorage.before_action)[0].click();
      localStorage.removeItem('before_action');
      console.log("1");
    }
    if (!isEmpty(localStorage.before_action)) {
      if(!isEmpty($("#"+localStorage.before_action))){
        $("#"+localStorage.before_action)[0].click();
        localStorage.removeItem('before_action');
        console.log("2");
      }
    }
  }
  setLoginModal(modal="hidden") {
    this.setState({login_modal:modal});
  }
  setBeforeAction(action=null) {
    this.setState({before_action:action});
  }
  render() {
    return (
      <div className="container"
        onClick={(event)=>{
          if(!event.target.classList.contains('open-btn') && !document.querySelector(".sidenav").contains(event.target))
            this.setState({sidenav:false,messages:this.props.room.messages});
        }}
      >
        <Sidebar 
          history={this.props.history} 
          setLoginModal={this.setLoginModal}  
          beforeAction={this.setBeforeAction}
          show={this.state.sidenav} 
          setState={state=>this.setState(state)}
        />
        <Navbar/>
        <div className="main min-height-100">
          <div className="row pb-3">
            <div className="col-sm-12 pt-2">
              <RoomHeader/>
            </div>
            <div className="col-sm-12 mt-3">
              {this.addCandleButtom()}
            </div>
          </div>
          <div className="row pb-150 justify-content-md-center">
            {this.candles()}
          </div>
          <NavbarFooter setLoginModal={this.setLoginModal} beforeAction={this.setBeforeAction}/>
        </div>
        <LoginModal setLoginModal={this.setLoginModal} modal={this.state.login_modal} beforeAction={this.setBeforeAction} action={this.state.before_action} state={this.state}/> 
      </div>


    );
  }
}
CandleRoom.propTypes = {
  updateRoom: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  room: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  user: state.user,
  room: state.room,
  errors: state.errors
});
export default connect(
  mapStateToProps,
  { updateRoom }
)(CandleRoom);