import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Image1 from '../../images/book_images/image_1.jpg';
import Image2 from '../../images/book_images/image_2.jpg';
import Image3 from '../../images/book_images/image_3.jpg';
import Image4 from '../../images/book_images/image_4.jpg';
import $ from "jquery";

const isEmpty = require('is-empty');

class BookModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
        gallery_scroll:305,
        price_one:"19,90€",
        price_two:"64,90€",
        price_three:"64,90€",
    };
  }
  componentDidMount(){
    $('#book-modal').on('shown.bs.modal', function (e) {
      document.querySelector("#scroll-gallery").scrollLeft = 170;
    })
  }
  render() {
    console.log("render");
    return (
      <div className="modal fade" id="book-modal" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{zIndex:2002}}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content" style={{color:'var(--first-color)'}}>
            <div className="modal-header pt-2 pb-0 d-block border-0">
              <h5 className="text-center m-0">UN RECUERDO PERFECTO</h5>
            </div>
            <div className="modal-body py-1">
              <div className="row text-center">
                <div className="col-12">
                  <p className="mb-2" style={{lineHeight:'1'}}>Recopila toda la actividad del anuario en un formato muy divertido.</p>
                </div>
                <div className="col-12 px-1" id="scroll-gallery" style={{overflowX:'scroll'}} ref='scroller'>
                  <div  style={{width:'895px', left:'300px'}}>
                    <img className="img-fluid d-inline-block mx-1" src={Image1} style={{height:'215px'}} alt=""/>
                    <img className="img-fluid d-inline-block mx-1" src={Image2} style={{height:'215px'}} alt=""/>
                    <img className="img-fluid d-inline-block mx-1" src={Image3} style={{height:'215px'}} alt=""/>
                    <img className="img-fluid d-inline-block mx-1" src={Image4} style={{height:'215px'}} alt=""/>
                  </div>
                </div>
                <div className="col-12 mt-3 mb-2">
                  <div className="row">
                    <div className="col-7 pl-2 pr-0 text-left">
                      <p className="m-0" style={{fontSize:'smaller'}}>Recibirás <span className="font-weight-bold">en tu correo</span> el libro digital con toda la actividad del anuario.</p>
                    </div>
                    <div className="col-5 px-2">
                      <div 
                        className="btn bg-first-color text-white w-100" style={{lineHeight:'normal', borderRadius:'10px'}}
                        onClick={e=>{
                          e.preventDefault();
                          // window.open("https://tienda.eternify.es/shop/?add-to-cart=10&billing_funeral_id="+this.props.room.id+"&user_email="+(!isEmpty(this.props.user.email)?this.props.user.email:''),'_blank');
                          window.name = "La diversiva";
                          window.open("https://tienda.eternify.es/?user_host="+window.location.host+"&billing_funeral_id="+this.props.room.id+"&user_email="+(!isEmpty(this.props.user.email)?this.props.user.email:''),'Tienda Eternify');
                        }}
                      >
                        <span className="">Libro Digital</span>
                        <span className="font-weight-bold d-inline-block">{this.state.price_one}</span>
                      </div>
                    </div>
                  </div>
                  <hr className="mx-5 my-3" style={{borderTop: '1px solid var(--first-color)'}}/>
                  <div className="row">
                    <div className="col-7 pl-2 pr-0 text-left">
                      <p className="m-0" style={{fontSize:'smaller'}}>Recibirás <span className="font-weight-bold">en tu casa el libro editado</span> y en tu correo <span className="font-weight-bold">el libro digital</span> con toda la actividad del anuario.</p>
                      <p className="text-dark m-0" style={{fontSize:'smaller'}}>Gastos de envío incluidos.</p>
                    </div>
                    <div className="col-5 px-2">
                      <div 
                        className="btn bg-second-color text-white w-100" style={{lineHeight:'normal', borderRadius:'10px'}}
                        onClick={e=>{
                          e.preventDefault();
                          // window.open("https://tienda.eternify.es/shop/?add-to-cart=10&billing_funeral_id="+this.props.room.id+"&user_email="+(!isEmpty(this.props.user.email)?this.props.user.email:''),'_blank');
                          window.name = "La diversiva";
                          window.open("https://tienda.eternify.es/product/anuario-editado-en-papel/?user_host="+window.location.host+"&billing_funeral_id="+this.props.room.id+"&user_email="+(!isEmpty(this.props.user.email)?this.props.user.email:''),'Tienda Eternify');
                        }}
                      >
                        <span className="d-block">Libro en Papel</span>
                        <span className="d-block" style={{lineHeight:'.8'}}>+</span>
                        <span className="d-block">Libro Digital</span>
                        <span className="font-weight-bold d-inline-block">{this.state.price_two}</span>
                      </div>
                    </div>
                  </div>
                  <hr className="mx-5 my-3" style={{borderTop: '1px solid var(--first-color)'}}/>
                  <div className="row">
                    <div className="col-7 pl-2 pr-0 text-left">
                      <p className="m-0" style={{fontSize:'smaller'}}>Recibirás en tu casa el libro en papel con toda la actividad del anuario.</p>
                      <p className="text-dark m-0" style={{fontSize:'smaller'}}>Gastos de envío incluidos.</p>
                    </div>
                    <div className="col-5 px-2">
                      <div 
                        className="btn bg-first-color text-white w-100" style={{lineHeight:'normal', borderRadius:'10px'}}
                        onClick={e=>{
                          e.preventDefault();
                          // window.open("https://tienda.eternify.es/shop/?add-to-cart=10&billing_funeral_id="+this.props.room.id+"&user_email="+(!isEmpty(this.props.user.email)?this.props.user.email:''),'_blank');
                          window.name = "La diversiva";
                          window.open("https://tienda.eternify.es/product/anuario-editado-en-papel/?user_host="+window.location.host+"&billing_funeral_id="+this.props.room.id+"&user_email="+(!isEmpty(this.props.user.email)?this.props.user.email:''),'Tienda Eternify');
                        }}
                      >
                        <span className="d-block">Libro en Papel</span>
                        <span className="font-weight-bold d-inline-block">{this.state.price_three}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer p-1">
              <button type="button" className="btn text-secondary" data-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
BookModal.propTypes = {
  user: PropTypes.object.isRequired,
  room: PropTypes.object.isRequired,
  app_settings: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  user: state.user,
  room: state.room,
  app_settings: state.app_settings,
  errors: state.errors
});
export default connect(
  mapStateToProps,
)(withRouter(BookModal));