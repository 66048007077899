import React, { Component } from "react";
// import { Link } from "react-router-dom";
import { connect } from "react-redux";
//resources
import PropTypes from "prop-types";
import Sidebar from "../layout/Sidebar.component";
import Navbar from "../layout/Navbar.component";
import NavbarFooter from "../layout/NavbarFooter.component";
import RoomHeader from "../layout/RoomHeader.component";
import LoginModal from "../auth/LoginModal.component";
// import Whatsapp from "../others/Whatsapp.component";
import ProgressBar from "../layout/ProgressBar.component";
// images
import UserIcon from '../../images/user-icon.png';
import $ from "jquery";
import 'bootstrap/dist/js/bootstrap.js';
import ShowErrors from '../errors/ShowErrors.component';
import MessageOptions from '../Message/Options.component';
import axios from "axios";
import { updateRoom } from "../../actions/Room.action";
import { setCurrentUser_ } from "../../actions/User.action";
import { setAppSettings } from "../../actions/AppSettings.action";
import FormData from 'form-data';
import imageEXIF from "../others/imageEXIF.component";
import ImageCutter from "../others/ImageCutter.component";

// eslint-disable-next-line
const isEmpty = require('is-empty');
let url = window.location.origin;
if (process.env.REACT_APP_ENV === "local")
    url = process.env.REACT_APP_API_URL;

class PhoneRoom extends Component {
	constructor() {
		super();
		this.messages = this.messages.bind(this);
		this.onChange = this.onChange.bind(this);
		this.updateRoom = this.updateRoom.bind(this);
		this.profilePhoto = this.profilePhoto.bind(this);
		this.handleChangeFile = this.handleChangeFile.bind(this);
		this.modal = this.modal.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.messageToEdit = this.messageToEdit.bind(this);
		this.closeRoomWarning = this.closeRoomWarning.bind(this);
		this.resetState = this.resetState.bind(this);
		this.messageDeleting = this.messageDeleting.bind(this);
		this.setLoginModal = this.setLoginModal.bind(this);
		this.setBeforeAction = this.setBeforeAction.bind(this);
		this.messageSuggestions = this.messageSuggestions.bind(this);
		this.state = {
			messages:[],
			max_text_len:300,
			text:"",
			user_photo:null,
			message_file:null,
			message_image_preview:null,
			profile_photo_preview:null,
			message_id_to_edit:null,
			message_file_updated:false,
			refresh_process_id:null,
			info_text:"",
			message_submiting:false,
			login_modal:"hidden",
			before_action:null,
			message_suggestions:[],
			load_percentage:0,
      		sidenav:false,
      		option_message_idx:null,
      		profile_src:null
		};
	}
	resetState(){
		console.log("is reseted");
		this.setState({
						text:"",
						user_photo:null,
						message_file:null,
						message_image_preview:null,
						profile_photo_preview:null,
						message_submiting:false,
					});
		this.forceUpdate();
	}
	onChange = e => {
		this.setState({ [e.target.id]: e.target.value });
	};
	onSubmit = e => {
		e.preventDefault();
		var form = document.getElementsByClassName('needs-validation')[1];
		form.classList.add('was-validated');
		if (form.checkValidity()) {
			if (isEmpty(this.props.user)) {
				// this.setLoginModal("show");
					$("#custom-modal").css("z-index","1000");
					$("#login-modal").modal("show");
					$("#custom-modal").modal("hide");

					this.setState({
						login_modal:'show',
						before_action:'write'
					})
				return 0;
			}
			if (isEmpty(this.state.text) && isEmpty(this.state.message_file))
				return 0;
			var formData = new FormData();
			if (this.state.user_photo) {
				formData.append("user_photo", this.state.user_photo);
			}
			if (this.state.message_file) {
				formData.append("message_file", this.state.message_file);
			}
			if (this.state.text) {
				formData.append("message", this.state.text);
			}
			formData.append("user_id", this.props.user.id);
			formData.append("funeral_id", this.props.room.id);
			this.setState({message_submiting:true});
			if (!isEmpty(this.state.message_id_to_edit)) {
				formData.append("message_id", this.state.message_id_to_edit);
				axios.post(process.env.REACT_APP_API_URL+"/api/message/update",  formData, {
					"headers": {
						"Content-Type": "application/x-www-form-urlencoded",
						'content-type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW'
					},
					onUploadProgress: (event)=>{
		              const percentage = Math.round( (event.loaded * 100) / event.total );
		            	this.setState({load_percentage:percentage});
		            	// console.log('load:',percentage, event);
		            }
				}).then(res => {
					if (res.data.success) {
						if(document.querySelector("#custom-modal").style.display==='none')
							alert('Felicitaciones actualizada.');
						const obj = {
							code:this.props.room.id, 
							user_id:!isEmpty(this.props.user)?this.props.user.id:null,
							messages:res.data.messages
						};
						if (this.state.user_photo) {
							let src = url+"/api/web-user/"+this.props.user.web_user.id+"/photo?a" + new Date().getTime();
            				this.setState({profile_src: src});
            				let app_settings = this.props.app_settings;
            				app_settings.user_pic_url = src;
           					this.props.setAppSettings({app_settings:app_settings});
						}
						this.props.setCurrentUser_(res.data.message_);
						this.props.updateRoom(obj, this.props.history);
						$("#custom-modal").modal('hide');

						//reset form
						let state = { 
							message_id_to_edit: null,
						 	text:"", 
						 	info_text:"",
						 	load_percentage:0,
						 	messages:res.data.messages
						}
						if (this.state.message_file) {
							state.message_file_updated = this.state.message_id_to_edit;
						}
						//refresh process shutdown
						if (isEmpty(this.state.refresh_process_id)) {
							state.refresh_process_id = setInterval(()=>{this.updateRoom(false)},process.env.REACT_APP_REFRESH_RATE);
						}
						this.setState(state);
						this.forceUpdate();
						$("#img-preview").attr('src',"");
						$(".modal-footer > button").text("Listo");
						$("label[for='message_file']").text("Acompañar con una foto");
						
						var form = document.getElementsByClassName('needs-validation')[1];
						if (form) {
							form.classList.remove('was-validated');
						}
						if (res.data.message_.status !== 'approved') {
							alert("El contenido de este mensaje podría ser indebido, estará siendo revisado por el equipo de Eternify. Pronto se tendra una resolución.");
						}
						// this.resetState();
					}else{
						// console.log(res)
					}
					// this.resetState();
				})
				// .catch(err =>{}
				// );	
			}else{
				axios.post(process.env.REACT_APP_API_URL+"/api/message/store",  formData, {
					"headers": {
						"Content-Type": "application/x-www-form-urlencoded",
						'content-type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW'
					},
					onUploadProgress: (event)=>{
		              const percentage = Math.round( (event.loaded * 100) / event.total );
		            	this.setState({load_percentage:percentage});
		            	// console.log('load:',percentage, event);
		            }
				}).then(res => {
					console.log(res);
					if (res.data.success) {
						if(document.querySelector("#custom-modal").style.display==='none')
							alert('Felicitaciones registrada.');
						const obj = {
							code:this.props.room.id,
							user_id:!isEmpty(this.props.user)?this.props.user.id:null,
							messages:res.data.messages
						};
						if (this.state.user_photo) {
							let src = url+"/api/web-user/"+this.props.user.web_user.id+"/photo?a" + new Date().getTime();
            				this.setState({profile_src: src});
            				let app_settings = this.props.app_settings;
            				app_settings.user_pic_url = src;
           					this.props.setAppSettings({app_settings:app_settings});
						}
			
						this.props.setCurrentUser_(res.data.message_);
						this.props.updateRoom(obj, this.props.history);

						//reset form
						let state = { 
							message_id_to_edit: null,
						 	text:"", 
						 	info_text:"",
						 	load_percentage:0,
						 	messages:res.data.messages
						}
						if (this.state.message_file) {
							state.message_file_updated = this.state.message_id_to_edit;
						}
						//refresh process shutdown
						if (isEmpty(this.state.refresh_process_id)) {
							state.refresh_process_id = setInterval(()=>{this.updateRoom(false)},process.env.REACT_APP_REFRESH_RATE);
						}
						this.setState(state);
						this.forceUpdate();
						$("#custom-modal").modal('hide');
						$("#img-preview").attr('src',"");
						$(".modal-footer > button").text("Listo");
						$("label[for='message_file']").text("Acompañar con una foto");
						//refresh process shutdown
						var form = document.getElementsByClassName('needs-validation')[1];
						if (form) {
							form.classList.remove('was-validated');
						}
						if (res.data.message_.status !== 'approved') {
							alert("El contenido de este mensaje podría ser indebido, estará siendo revisado por el equipo de Eternify. Pronto se tendra una resolución.");
						}
						// this.resetState();
					}else{
						// console.log(res)
					}
					// this.resetState();
				})
				.catch(err =>{}
				);	
			}
			
		}
	};
	handleChangeFile = async event => {
		// console.log(event);
		if (typeof event.target.files[0] === 'undefined')
			return 0;
		const file = event.target.files[0];
		const name = event.target.name;
		const state_prop = event.target.name==="message_file"?"message_image_preview":"profile_photo_preview";
		const result = await imageEXIF.getImageUrl(file);
		if (state_prop === "profile_photo_preview") {
			new ImageCutter(result, 400,(rslt)=>{
		      this.setState({[state_prop]:rslt.base64,[name]:rslt.blob});
		    });
		}else{
			this.setState({[state_prop]:result,[name]: file});
		}
	}
	updateRoom(reload = true){
		// console.log("updated");
	   const obj = {
	   	code:this.props.room.id, 
	   	user_id:!isEmpty(this.props.user)?this.props.user.id:null
	   };
	   this.props.updateRoom(obj, this.props.history);
	   if (reload) {
	   		window.location.reload();
	   }else{
	   	setTimeout(()=>{
	   		let state = {messages:this.props.room.messages};
	   		if(!isEmpty(this.props.app_settings.user_pic_url)){
	        	state.profile_src = this.props.app_settings.user_pic_url;
	    	}else if(!isEmpty(this.props.user) && !isEmpty(this.props.user.web_user.photo_id)){
		      state.profile_src = url+"/api/web-user/"+this.props.user.web_user.id+"/photo?" + new Date().getTime();
		    }
			this.setState(state);
		   	this.forceUpdate();
		},500);
	   	
	   }
  	}
  	messageDeleting(id){
		const obj = {
			"message":id,
			"user_id":this.props.user.id
		};
		// console.log(obj);
  		axios.get(process.env.REACT_APP_API_URL+"/api/message/"+id+"/delete", {params:obj}, {
			"headers": {
				"Content-Type": "application/x-www-form-urlencoded",
				'content-type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW'
			}
		}).then(res => {
			// console.log(res);
			if (res.data.success) {
				// console.log(res.data);
				this.updateRoom(false);
				this.setState({messages:
					this.state.messages.filter(function(message) {
						return message.id !== id;
					})
				});
			}else{
				console.log(res)
			}
			// this.resetState();
		})
		.catch(err =>{});	
  	}
  	messageToEdit(id){
  		this.setState({ message_id_to_edit: id });
  		this.forceUpdate(()=>{
  			$('#custom-modal').modal("show");
  		});
  	}
  	messageSuggestions(){
  		axios.get(process.env.REACT_APP_API_URL+"/api/message/suggestions")
  		.then(res => {
			// console.log(res);
			if (res.data.success) {
				// console.log(res.data);
				this.setState({message_suggestions:res.data.suggestions});
			}else{
				console.log(res)
			}
		})
		.catch(err =>{});
  	}
  	renderMessageSuggestions(){
  		let suggestions = [];
  		this.state.message_suggestions.forEach((value, index)=>{
  			const hr = index < this.state.message_suggestions.length?<hr style={{clear:'both'}}/>:null;
  			suggestions.push([
  				<div key={index} onClick={()=>{
  					this.setState({text:this.state.message_suggestions[index]});
  					document.querySelector("#button-suggetions").click()
  				}}>
  					<p>{value}</p>
  					<span style={{float:'right',marginBottom:'1rem'}}>usar</span>
  					{hr}
  				</div>
  			]);
  		});
  		return suggestions;
  	}
	componentDidMount(){
		if (isEmpty(this.props.room)) {
			this.props.history.push('/code');
		}
		let state = {messages:this.props.room.messages};
		if(!isEmpty(this.props.app_settings.user_pic_url)){
        	state.profile_src = this.props.app_settings.user_pic_url;
    	}else if(!isEmpty(this.props.user) && !isEmpty(this.props.user.web_user.photo_id)){
	      state.profile_src = url+"/api/web-user/"+this.props.user.web_user.id+"/photo?" + new Date().getTime();
	    }
		this.setState(state);

    	$('.carousel').carousel();
    	window.addEventListener('popstate', function(event) {
	         $(".modal-backdrop").remove();
	         $("body").removeClass('modal-open');
		}, false);
		const _this = this;
		$('#custom-modal').on('show.bs.modal', function (e) {
			//refresh process shutdown
			if (!isEmpty(_this.state.refresh_process_id)) {
				clearInterval(_this.state.refresh_process_id);
				_this.setState({refresh_process_id:null});
			}
			if (!_this.props.room.is_open) {
				_this.setState({info_text:"La sala esta cerrada, no se podrán registrar nuevos mensajes."}); 
			}
		})
		$('#custom-modal').on('hidden.bs.modal', function (e) {
			if (_this.state.load_percentage > 0)
				return 0;
			if (!$('#login-modal').is(':visible')) {
				_this.setState({ message_id_to_edit: null, text:"", info_text:""});
				$("#img-preview").attr('src',"");
				$(".modal-footer > button").text("Listo");
				$("label[for='message_file']").text("Acompañar con una foto");
				$("#message_file").removeAttr('capture');
				//refresh process shutdown
				if (isEmpty(_this.state.refresh_process_id)) {
					_this.setState({refresh_process_id:setInterval(()=>{_this.updateRoom(false)},process.env.REACT_APP_REFRESH_RATE)});
				}
				var form = document.getElementsByClassName('needs-validation')[1];
				if (form) {
					form.classList.remove('was-validated');
				}
				_this.resetState();
			}
		})
		$('#login-modal').on('hidden.bs.modal', function (e) {
			$('#custom-modal').css("z-index","2000");
			// $('#text').focus();
			if (!isEmpty(_this.state.text))
				$('#custom-modal').modal('show');
		})
		$('#options-message-modal').on('hidden.bs.modal', function (e) {
			$('#collapseOptonMessage').collapse('hide');
			$('#collapseOptonMessageInfo').collapse('hide');
		})
		$('#custom-modal').on('shown.bs.modal', function (e) {
			if (_this.state.load_percentage > 0)
				return 0;
			if (!isEmpty(_this.state.message_id_to_edit)) {
				let message = _this.state.messages.filter((message)=>{
					return message.id === _this.state.message_id_to_edit;
				});
				_this.setState({ text: message[0].text });
				$("#img-preview").attr('src',process.env.REACT_APP_API_URL+"/api/message/"+message[0].id+"/file");
				$(".modal-footer > button").text("Guardar");
				$("label[for='message_file']").text("Cambiar imagen");
				
			}
		})
		//refresh function
		this.updateRoom(false);
		this.setState({refresh_process_id:setInterval(()=>{this.updateRoom(false)},process.env.REACT_APP_REFRESH_RATE)});

		if (!isEmpty(localStorage.before_action)) {
			if (localStorage.before_action === "candle") {
				this.props.history.push("/room/candle");
			}else if(!isEmpty($("#"+localStorage.before_action))){
				const _this = this;
				if (!isEmpty(localStorage.before_action_image_preview) && localStorage.before_action_image_preview !=="null") {
					fetch(localStorage.before_action_image_preview)
					.then(res => res.blob())
					.then(blob => {
						blob.lastModifiedDate = new Date();
			    		blob.name = 'img';
						// console.log(blob);
						_this.setState({
							message_file:blob,
							message_image_preview:localStorage.before_action_image_preview
						});
						localStorage.removeItem('before_action_image_preview');
					})
				}
				if (!isEmpty(localStorage.before_action_image_user_preview) && localStorage.before_action_image_user_preview!=="null") {
					fetch(localStorage.before_action_image_user_preview)
					.then(res => res.blob())
					.then(blob => {
						blob.lastModifiedDate = new Date();
			    		blob.name = 'img';
						// console.log(blob);
						_this.setState({
							user_photo:blob,
							profile_photo_preview:localStorage.before_action_image_user_preview
						});
						localStorage.removeItem('before_action_image_user_preview');
					})
				}
				this.setState({
					text:localStorage.before_action_text,
				});
				$("#"+localStorage.before_action)[0].click();
				localStorage.removeItem('before_action');
				localStorage.removeItem('before_action_text');
				setTimeout(()=>{
					$('#submit-message').click();
				},2000);
			}
		}
		if (isEmpty(this.state.message_suggestions)) {
			this.messageSuggestions();
		}
	}
	modal(){
		if (this.props.room.is_open && isEmpty(this.state.info_text)) {
			let photoInput = <div className="form-group  mb-0">
								<label htmlFor="user_photo"><u>Subir una foto de perfil</u></label>
								<input 
									type="file" 
									className="form-control-file d-none" 
									id="user_photo" 
									name="user_photo" 
									accept="image/*"
									onChange={this.handleChangeFile}
								/>
							</div>
			let submitButton = null;
			if (this.state.message_submiting) {
				submitButton = <button type="submit" className="btn text-white mt-2 btn-et bg-third-color disabled" disabled>Guardando...</button>
			}else{
				submitButton = <button type="submit" id="submit-message" className="btn text-white mt-2 btn-et bg-third-color">Listo</button>
			}
			return(
				<div className="modal fade" id="custom-modal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={{zIndex:2001,overflow:'scroll'}}>
					<div className="modal-dialog modal-dialog-centered" role="document">
						<div className="modal-content mx-3">
							<form noValidate className="needs-validation" onSubmit={this.onSubmit}>
								<div className="modal-header d-block text-center pb-0">
									<div className="form-group d-flex justify-content-center">
										{this.profilePhoto()}
									</div>

									{photoInput}
								</div>
								<div className="modal-body row pt-1">
									<div className="form-group col-12 text-right mb-1">
										<span className="text-seconsary">{this.state.text?this.state.text.length:0}<small>/{this.state.max_text_len}</small></span>
									</div>
									<div className="form-group col-12">
											<textarea 
											className="form-control" 
											id="text" 
											name="text" 
											rows="3" 
											placeholder="Felicitaciones..." 
											onChange={this.onChange} 
											value={this.state.text?this.state.text:''}
											maxLength={this.state.max_text_len}
										/>
	                  					<ShowErrors name="message" frontendError="Este campo es requerido"/>
									</div>
									<div className="form-group col-6 pr-1">
										<a id="button-suggetions" className="btn bg-first-color mb-1 mt-1 btn-et text-white" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
									    	Sugerencias de Felicitaciones
										</a>
										
									</div>

									<div className="form-group col-6 pl-1">
										<label htmlFor="message_file" className="btn bg-second-color mb-1 mt-1 btn-et text-white">Acompañar con una foto</label>
										<input 
											type="file" 
											className="form-control-file d-none" 
											id="message_file" 
											name="message_file" 
											accept="image/*"
											onChange={this.handleChangeFile}
										/>
									</div>
									<div className="col-12">
										<div className="collapse" id="collapseExample">
										  <div className="card card-body" style={{maxHeight:'50vh', overflowY:'scroll'}}>
										    {this.renderMessageSuggestions()}
										  </div>
										</div>
									</div>
									<div className="col-12">
										<img className="img-fluid" id="img-preview" src={this.state.message_image_preview} alt=""/>
									</div> 
								</div>
								<ProgressBar percentage={this.state.load_percentage}/>
								<div className="modal-footer justify-content-center pt-0">
									{submitButton}
								</div>
							</form>
						</div>
					</div>
				</div>
			);
		}else{
			return(
				<div className="modal fade" id="custom-modal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
					<div className="modal-dialog modal-dialog-centered" role="document">
						<div className="modal-content mx-3">
							<div className="modal-header d-block text-center pb-0">
								¡Aviso!
							</div>
							<div className="modal-body">
								{this.state.info_text}
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
	messageProfilePhoto(message){
		// console.log(message);
		if (!isEmpty(message.user.web_user.photo_id)) {
			let img_src = '';
			if(!isEmpty(this.props.user) && !isEmpty(this.props.user.web_user.photo_id) && message.user.id === this.props.user.id){
				img_src = this.state.profile_src;
			}else{
				img_src =  process.env.REACT_APP_API_URL+"/api/web-user/"+message.user.web_user.id+"/photo";
			}
			// console.log(this.props.user, this.props.user.web_user.id, message.user.web_user.photo_id);
			return (<div 
				style={{
					backgroundImage:"url('"+img_src+"')",
					backgroundPosition: 'center',
					// backgroundRepeat: 'no-repeat',
				    backgroundSize: 'contain',
				    width:'22%',
				    height:'3rem'
				}}  
				className="img-fluid my-1 mx-0 img-circle">
			</div>);
		}else{
			return <div style={{width:'22%'}}><div className="profile-letter" style={{backgroundColor:'var(--first-color)'}}>{message.user.name.charAt(0)}</div></div>
		}
	}
	messages(){
		let messages = "";
		if (isEmpty(this.state.messages)) {
			messages = <div className="row p-4">
							<div className="col-sm-12 col-md-6">
								<p className="text-secondary">Aún no hay Felicitaciones, se el primero en dejar un mensaje.</p>
							</div>
						</div>
		}else{
			messages = this.state.messages.map((message, index)=>{
				let messageImage = null;
				let sufix = "";
				if ((!isEmpty(this.props.user) && message.user.id === this.props.user.id)) {
					sufix = " (Tu)";
				}
				if(message.file_id){
					messageImage = <img className="img-fluid" id={'message-img-'+message.file_id} src={process.env.REACT_APP_API_URL+"/api/message/"+message.id+"/file"+( (this.state.message_file_updated === message.id)?'?' + new Date().getTime():'')} alt="" style={{opacity:message.status!=='approved'?0.5:1}}/>
				}
				if (
					(
						message.status === 'approved' || (!isEmpty(this.props.user) && message.user.id === this.props.user.id)
					) 
					|| 
					( !isEmpty(this.props.user) && this.props.user.web_user.funerals_to_manage.indexOf(this.props.room.id)>=0 ) 
					){
					return(
						<div key={index} className={(message.status === 'approved')?"card p-2 shadow":"card p-2 shadow bg-light text-secondary"}>
							<div className="card-title mb-1 row">
								<div className="col-9 row m-0 align-items-center">
									{this.messageProfilePhoto(message)}
									<span className="font-weight-bold m-0 pl-2" style={{width:'78%'}}>{message.user.name+sufix}</span>
								</div>
								<div className="col-3 d-flex align-items-center justify-content-center pr-2" data-target="#options-message-modal">
									<div className="btn btn-sm my-dropdown-toggle" onClick={()=>{
										this.setState({option_message_idx:index});
										this.forceUpdate(()=>{$("#options-message-modal").modal('show')});
									}}></div>
								</div>
							</div>
							<div className="card-body p-0">
								<p className="mb-1" style={{wordBreak:'break-word'}}>
									{message.text}
								</p>
								{messageImage}
							</div>
						</div>
					);
				}else{
					return null;
				}
				
			});
		}
		return messages;
	}
	profilePhoto(){
		let img = UserIcon;
		if (this.state.profile_photo_preview) {
			img = this.state.profile_photo_preview;
		}else if(!isEmpty(this.props.user) && !isEmpty(this.props.user.web_user.photo_id)){
			img = this.state.profile_src;
		}else if (!isEmpty(this.props.user)) {
			return <div className="profile-letter" style={{backgroundColor:'var(--first-color)',width:'5rem',height:'5rem'}}>{this.props.user.name.charAt(0)}</div>
		}
		return <div className="profile-pic" style={{backgroundImage:'url('+img+')'}}></div>;
	}
	closeRoomWarning(){
		if (!this.props.room.is_open) {
			return (
				<div className="row">
					<div className="col bg-warning text-center">
						<p className="text-dark m-1">¡Esta sala se ha cerrado!</p>
					</div>
				</div>
			);
		}
	}
	setLoginModal(modal="hidden") {
		this.setState({login_modal:modal});
	}
	setBeforeAction(action=null) {
		this.setState({before_action:action});
	}
	render() {
		return (
			<div className="container" 
				onClick={(event)=>{
		          if(!event.target.classList.contains('open-btn') && !document.querySelector(".sidenav").contains(event.target))
		            this.setState({sidenav:false,messages:this.props.room.messages});
		    	}}
		    >
				<Sidebar 
					history={this.props.history} 
					setLoginModal={this.setLoginModal} 
					beforeAction={this.setBeforeAction}
					show={this.state.sidenav} 
					setState={state=>this.setState(state)}
					updateRoom={this.updateRoom}
				/>
				<Navbar/>
				<div className="row mb-5 pb-5 min-height-100">
					<div className="col-sm-12 pt-2">
						<RoomHeader/>
						{this.closeRoomWarning()}
						<div className="row p-4 bg-clear mt-4">
							<div className="card-columns mb-5" >
								{this.messages()}	
							</div>
						</div>					
					</div>
				</div>
				<NavbarFooter setLoginModal={this.setLoginModal} beforeAction={this.setBeforeAction}/>
				{this.modal()}
				<LoginModal setLoginModal={this.setLoginModal} modal={this.state.login_modal} beforeAction={this.setBeforeAction} action={this.state.before_action} state={this.state}/> 
				<MessageOptions 
					messageIdx={this.state.option_message_idx} 
					massages={this.state.messages} 
					messageToEdit={this.messageToEdit}
					messageToDelete={this.messageDeleting}
				/>
			</div>
		);
	}
}
PhoneRoom.propTypes = {
	updateRoom: PropTypes.func.isRequired,
	setCurrentUser_: PropTypes.func.isRequired,
	setAppSettings: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
	room: PropTypes.object.isRequired,
	app_settings: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
	user: state.user,
	room: state.room,
	app_settings: state.app_settings,
	errors: state.errors
});
export default connect(
	mapStateToProps,
	{ updateRoom, setCurrentUser_, setAppSettings }
)(PhoneRoom);